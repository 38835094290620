import React  from "react";
import Demo from './demo/index'
import './style.scss'
const Index=()=>{
  return (
    <div className="main"> 
      <Demo/>
  </div>
  )
}

export default Index