import React, { useState } from "react";
import clone from './img/clone.svg'
import logo from './img/logo.svg'
import erert from './img/land.png'
import chromeImg from './img/ChromeImg.svg'
import './style.scss';
import { Tooltip } from "antd";
const list = [{
  title: 'Borrow',
  url: 'https://fi.xmud.xyz'
}, {
  title: 'Airdrop',
  url: 'https://xmud.xyz'
}, {
  title: 'Docs',
  url: 'https://xmud.gitbook.io/'
}];

const footList = [{
  title: 'Borrow Policy',
  url: 'https://xmud.gitbook.io/xmud-overview/legal-stuff/term-of-use'
}, {
  title: 'Privacy Policy',
  url: 'https://xmud.gitbook.io/xmud-overview/legal-stuff/privacy-policy'
}, {
  title: 'Term of use',
  url: 'https://xmud.gitbook.io/xmud-overview/legal-stuff/term-of-use'
}]


const Index = () => {
  const [selectName, setSelectName] = useState('');

  return (
    <div className="index-main">
      <div className="head">
        <div className="logo"> <img src={logo} alt="" /></div>
        <div className="memu">
        <a className='item'  href={list[0].url} target="_blank">{list[0].title}</a>
        <Tooltip title="Please download the XMUD Chrome plugin and log in to claim Airdrop rewards"><div className='item'>{list[1].title}</div></Tooltip>
        <a className='item'  href={list[2].url} target="_blank">{list[2].title}</a>
        

        </div>
        <a className="clone" href="https://twitter.com/xmudxyz" target="_blank">
          <img src={clone} alt="" />
          </a>
      </div>
      <div className="content">
        <div className="erert"> <img src={erert} alt="" /></div>
        <div className="introduce">
          <div className="title">
            <div className="character">Raise your Character</div>
            <br />
            <div className="twitter">Take profit on Twitter</div>
          </div>
          <div className="tip">Install the extension and receive your character directly on the Twitter<br />interface. take good care of vour character to earn profits.</div>
          <div className="browse">Add to browse</div>
          <a className="chrome" href="https://chromewebstore.google.com/detail/xmud/ihkkkicmegagoanfajlifkphbammfokn" target="_blank"> <img src={chromeImg} alt="" /> <span>Chrome</span></a>

        </div>
      </div>


      <div className="foot">
        {footList.map(res => {
          return (
            <a href={res.url} className="item" target="_blank" key={res.title}>{res.title}</a>
          )
        })}
      </div>
    </div>
  )
}

export default Index