
import {Outlet} from 'react-router-dom';
// import Footer from './footers';
// import Herder from './header';
import './index.scss';

const BaseLayout = (props) => {
  // const {showHeaders=true} =props;
  return (
    <div className='layout-box'>
      {/* {showHeaders &&  <Herder />} */}
      <div className='layout-content'>
        <Outlet />
      </div>
      {/* <Footer /> */}
    </div>
  );

};
export default BaseLayout;