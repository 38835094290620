import './App.css';
import './assets/css/global.scss'
import router from './router';
import { useRoutes } from 'react-router-dom';
function App() {
  const element = useRoutes(router);
  return (
    <div className="App">
      {element}
    </div>
  );
}

export default App;
