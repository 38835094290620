/* eslint-disable import/no-anonymous-default-export */
import { Navigate } from 'react-router-dom';
import Home from '../pages/index'
import Layout from '../layouts/index'
import { menuRouter } from './menuRouter';

export default [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      ...menuRouter
    ],
  }
];
