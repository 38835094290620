import Home from '../pages/index'

const menuRouter = [
  {
    key: 1,
    label: 'Introduction',                           
    path: 'Home',
    element: <Home />,
  }
];


export {menuRouter}